// eslint-disable-next-line
/* eslint-disable */

import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

@Module
export default class AuthModule extends VuexModule {
  errors = {};
  // user = {} as User;
  user =
    JwtService.getUser() != null
      ? JwtService.getUser()
      : {
          email: '',
          nombre: '',
          apellido: '',
          state: '',
          google: '',
          codigoPais: '',
          fechaCreacion: '',
          celular: '',
          _id: '',
          hospital: '',
          idFarmalisto: '',
        };
  response = {};
  isAuthenticated = !!JwtService.getToken();
  rol = JwtService.getRol() != null ? JwtService.getRol() : '';

  rutas = {
    ADMIN: 'welcome',
    ESTADISTICO: 'statistics',
    SELLER: 'seller',
    SELLER_LIMITED: 'seller-limited',
    CALL_CENTER: 'welcome',
    CLIENTE: 'profile-orders',
    COMERCIAL: 'profile-orders',
    EMPRESARIAL: 'profile-orders',
    LABORATORIO: 'laboratory-orders',
    PROVEEDOR: 'laboratorio-mis-citas',
    CUPONES: 'coupons',
    // 'CONVENIO': 'convenio-mis-citas',
    CONVENIO: 'convenio-mis-citas',
  };

  /**
   * Get current user object
   * @returns User
   */
  get getResponsePerfil() {
    return this.response;
  }
  get currentRouter() {
    return this.rutas;
  }
  get currentUser() {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  get currentRol() {
    return this.rol;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    console.log('data: ', data);
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = {};
    this.rol = data.user.role;
    JwtService.saveRol(data.user.role);
    JwtService.saveToken(data.token);
    JwtService.saveUser(data.user);
  }
  @Mutation
  [Mutations.SET_PERFIL](res) {
    this.response = res;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {
      email: '',
      nombre: '',
      apellido: '',
      state: '',
      google: '',
      codigoPais: '',
      fechaCreacion: '',
      celular: '',
      _id: '',
      hospital: '',
      idFarmalisto: '',
    };
    this.errors = [];
    JwtService.destroyRol();
    JwtService.destroyToken();
    JwtService.destroyUser();
  }

  @Action
  async [Actions.LOGIN](credentials: object) {
    return await ApiService.post('login', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        let error =
          typeof response.data['errors'] != 'undefined'
            ? response.data['errors']
            : response.data['err']['message'];
        console.log('error: ', error);
        this.context.commit(Mutations.SET_ERROR, error);
      });
  }

  @Action
  async [Actions.LOGIN_GOOGLE](data: object) {
      this.context.commit(Mutations.SET_AUTH, data);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post('register', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_PERFIL](form) {
    return ApiService.post('/update-perfil', form)
      .then(({ data }) => {
        console.log('UPDATE_PERFIL data: ', data);
        this.context.commit(Mutations.SET_PERFIL, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post('forgot_password', payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post('verify_token', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
