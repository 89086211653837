// eslint-disable-next-line
/* eslint-disable */
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import { Mutations, Actions } from '@/store/enums/StoreEnums';

const userData = {
  name: 'username',
};

const routes: Array<any> = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/layouts/main-layout/MainLayout.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/dashboards/MainDashboard.vue'),
        meta: {
          pageTitle: 'Dashboard',
          breadcrumbs: ['Dashboard'],
        },
      },
      {
        path: '/ambulance/dashboard',
        name: 'ambulance-dashboard',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/dashboards/AmbulanceDashboard.vue'),
        meta: {
          pageTitle: 'Ambulancia',
          breadcrumbs: ['Ambulancia'],
        },
      },
      {
        path: '/ambulances',
        name: 'ambulances',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/ambulances/AmbulancesIndex.vue'),
        meta: {
          pageTitle: 'Ambulancias',
          breadcrumbs: ['Ambulancias'],
        },
      },
      {
        path: '/ambulances/orders',
        name: 'ambulances-orders',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/ambulances-orders/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Ordenes de Ambulancias',
          breadcrumbs: ['Ordenes de Ambulancias'],
        },
      },
      {
        path: '/farmacia/orders',
        name: 'farmacia-orders',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/pharmacy-orders/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Ordenes de Farmacia Farmalisto',
          breadcrumbs: ['Ordenes de Farmacia Farmalisto'],
        },
      },
      {
        path: '/farmacia/orders-prix',
        name: 'farmacia-orders-prix',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/pharmacy-orders/OrdersIndexPRIX.vue'),
        meta: {
          pageTitle: 'Ordenes de Farmacia PRIX',
          breadcrumbs: ['Ordenes de Farmacia Prix'],
        },
      },
      {
        path: '/consultas-medicas/orders',
        name: 'consultas-medicas-orders',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/consulta-medica-orders/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Consultas Médicas',
          breadcrumbs: ['Consultas Médicas'],
        },
      },
      {
        path: '/seguros/orders',
        name: 'seguros-orders',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/seguros/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Órdenes de Seguros',
          breadcrumbs: ['Órdenes de Seguros'],
        },
      },
      {
        path: '/seguros/orders-asegurados',
        name: 'seguros-orders-asegurados',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/seguros/OrdersPeople.vue'),
        meta: {
          pageTitle: 'Órdenes de Asegurados',
          breadcrumbs: ['Órdenes de Asegurados'],
        },
      },
      {
        path: '/suscripciones/orders',
        name: 'suscripciones-orders',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/paquetes/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Planes de Salud B2C',
          breadcrumbs: ['Planes de Salud B2C'],
        },
      },
      {
        path: '/suscripciones/orders-autopay',
        name: 'suscripciones-orders-autopay',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/paquetes/OrdersPromotionIndex.vue'),
        meta: {
          pageTitle: 'Planes de Salud con pago recurrente',
          breadcrumbs: ['Planes de Salud con pago recurrente'],
        },
      },
      {
        path: '/seller/suscripciones-b2c/orders',
        name: 'seller-suscripciones-b2c-orders',
        role: ['SELLER', 'SELLER_LIMITED'],
        component: () => import('@/views/clinic/seller/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Planes de Salud B2C',
          breadcrumbs: ['Planes de Salud B2C'],
        },
      },
      {
        path: '/seller/suscripciones-b2c/orders-autopay',
        name: 'seller-suscripciones-b2c-orders-promotion',
        role: ['SELLER', 'SELLER_LIMITED'],
        component: () =>
          import('@/views/clinic/seller/OrdersPromotionIndex.vue'),
        meta: {
          pageTitle: 'Planes de Salud con pago recurrente',
          breadcrumbs: ['Planes de Salud con pago recurrente'],
        },
      },
      {
        path: '/suscripciones/orders-users',
        name: 'suscripciones-orders-users',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/paquetes/Ordersb2bUser.vue'),
        meta: {
          pageTitle: 'Planes de Salud B2B',
          breadcrumbs: ['Planes de Salud B2B Usuarios'],
        },
      },
      {
        path: '/suscripcioness-seller-b2b/orders-users',
        name: 'suscripciones-orders-users-seller',
        role: ['SELLER'],
        component: () => import('@/views/clinic/seller/Ordersb2bUser.vue'),
        meta: {
          pageTitle: 'Planes de Salud B2B',
          breadcrumbs: ['Planes de Salud B2B Usuarios'],
        },
      },
      {
        path: '/suscripciones-seller-b2b/orders-company',
        name: 'suscripciones-orders-b2b-company',
        role: ['SELLER'],
        component: () => import('@/views/clinic/seller/Ordersb2bCompany.vue'),
        meta: {
          pageTitle: 'Planes de Salud B2B',
          breadcrumbs: ['Planes de Salud B2B Empresa'],
        },
      },
      {
        path: '/suscripciones/orders-company',
        name: 'suscripciones-orders-company',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/paquetes/Ordersb2bCompany.vue'),
        meta: {
          pageTitle: 'Planes de Salud B2B',
          breadcrumbs: ['Planes de Salud B2B Empresa'],
        },
      },
      {
        path: '/suscripciones/products',
        name: 'paquetes-products',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/paquetes/Index.vue'),
        meta: {
          pageTitle: 'Planes de Salud',
          breadcrumbs: ['Planes de Salud'],
        },
      },
      {
        path: '/suscripciones/products-b2b',
        name: 'paquetes-products-b2b',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/paquetes/B2B/Index.vue'),
        meta: {
          pageTitle: 'Planes de Salud',
          breadcrumbs: ['Planes de Salud'],
        },
      },
      {
        path: '/cita',
        name: 'cita-view',
        role: [
          'USER_ROLE',
          'ADMIN',
          'CALL_CENTER',
          'CLIENTE',
          'COMERCIAL',
          'LABORATORIO',
          'PROVEEDOR',
          'EMPRESARIAL',
          '',
          null,
          undefined,
        ],
        component: () => import('@/views/cita/Index.vue'),
        meta: {
          pageTitle: 'Cita seleccionada',
          breadcrumbs: ['Cita seleccionada'],
        },
      },

      {
        path: '/',
        name: 'laboratorios',
        component: () => import('@/components/page-layouts/Perfil.vue'),
        children: [
          {
            path: 'mis-ordenes',
            name: 'laboratorio-mis-ordenes',
            role: ['ADMIN', 'LABORATORIO', 'CALL_CENTER'],
            component: () => import('@/views/laboratorio/MisOrdenes.vue'),
            meta: {
              pageTitle: 'Mis ordenes',
              breadcrumbs: ['Mis ordenes'],
            },
          },
          {
            path: 'mis-citas',
            name: 'laboratorio-mis-citas',
            role: ['ADMIN', 'LABORATORIO', 'CALL_CENTER', 'PROVEEDOR'],
            // role: ['ADMIN', 'LABORATORIO', 'CALL_CENTER', 'CLIENTE', 'COMERCIAL', 'EMPRESARIAL', 'EMPRESARIAL'],
            component: () => import('@/views/laboratorio/MisCitas.vue'),
            meta: {
              pageTitle: 'Mis citas',
              breadcrumbs: ['Mis citas'],
            },
          },
          {
            path: 'mis-citas-convenio',
            name: 'convenio-mis-citas',
            role: ['ADMIN', 'CONVENIO'],
            component: () =>
              // import("@/views/laboratorio/MisCitas.vue"),
              import('@/views/clinic/profile/MisCitas.vue'),
            meta: {
              pageTitle: 'Mis citas',
              breadcrumbs: ['Mis citas'],
            },
          },
        ],
      },

      {
        path: '/agenda-ambulancia',
        name: 'agenda-ambulancia-view',
        role: [
          'USER_ROLE',
          'ADMIN',
          'CALL_CENTER',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'LABORATORIO',
          'ESTADISTICO',
          'PROVEEDOR',
          null,
          undefined,
        ],
        component: () => import('@/views/agenda/Index.vue'),
        meta: {
          pageTitle: 'Orden',
          breadcrumbs: ['Orden'],
        },
      },

      {
        path: '/welcome',
        name: 'welcome',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/Welcome.vue'),
        meta: {
          pageTitle: 'Bienvenido',
          breadcrumbs: ['Bienvenido'],
        },
      },
      {
        path: '/reportes',
        name: 'reportes',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () => import('@/views/reportes/index.vue'),
        meta: {
          pageTitle: 'Reportes Custom',
          breadcrumbs: ['Reportes'],
        },
      },
      {
        path: '/reportes/meeting-doctors/chats',
        name: 'reportes chats',
        role: ['ADMIN', 'ESTADISTICO', 'CALL_CENTER'],
        component: () => import('@/views/reportes/MeetingDoctors/Chats.vue'),
        meta: {
          pageTitle: 'Reportes Chats',
          breadcrumbs: ['Reportes'],
        },
      },
      {
        path: '/reportes/meeting-doctors/videos',
        name: 'reportes videos',
        role: ['ADMIN', 'ESTADISTICO', 'CALL_CENTER'],
        component: () => import('@/views/reportes/MeetingDoctors/Video.vue'),
        meta: {
          pageTitle: 'Reportes Videollamadas',
          breadcrumbs: ['Reportes'],
        },
      },
      {
        path: '/comercial/placas',
        name: 'comercial placas',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/policias/Index.vue'),
        meta: {
          pageTitle: 'Números de Empleados',
          breadcrumbs: ['Comercial'],
        },
      },
      {
        path: '/comercial/products',
        name: 'comercial products',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/policias/Products/Index.vue'),
        meta: {
          pageTitle: 'Membresías',
          breadcrumbs: ['Comercial'],
        },
      },
      {
        path: '/comercial/orders',
        name: 'comercial orders',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/policias/Orders/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Ordenes',
          breadcrumbs: ['Comercial'],
        },
      },
      {
        path: '/comercial/orders-beneficiarios',
        name: 'comercial orders beneficiarios',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import(
            '@/views/clinic/policias/BeneficiarioOrders/BeneficiariosOrders.vue'
          ),
        meta: {
          pageTitle: 'Ordenes de Beneficiarios',
          breadcrumbs: ['Comercial'],
        },
      },
      {
        path: '/comercial/empresas',
        name: 'comercial empresas',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/policias/Empresas.vue'),
        meta: {
          pageTitle: 'Empresas',
          breadcrumbs: ['Comercial'],
        },
      },
      {
        path: '/statistics',
        name: 'statistics',
        role: ['ESTADISTICO'],
        component: () => import('@/views/clinic/statistics/Index.vue'),
        meta: {
          pageTitle: 'Bienvenido',
          breadcrumbs: ['Bienvenido'],
        },
      },
      {
        path: '/seller',
        name: 'seller',
        role: ['SELLER'],
        component: () => import('@/views/clinic/seller/Index.vue'),
        meta: {
          pageTitle: 'Bienvenido',
          breadcrumbs: ['Bienvenido'],
        },
      },
      {
        path: '/seller-limited',
        name: 'seller-limited',
        role: ['SELLER_LIMITED'],
        component: () => import('@/views/clinic/seller/Index2.vue'),
        meta: {
          pageTitle: 'Bienvenido',
          breadcrumbs: ['Bienvenido'],
        },
      },
      {
        path: '/categories',
        name: 'categories',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/categories/CategoriesIndex.vue'),
        meta: {
          pageTitle: 'Categorías',
          breadcrumbs: ['Categorías'],
        },
      },
      {
        path: '/customers',
        name: 'customers',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/customers/CustomersIndex.vue'),
        meta: {
          pageTitle: 'Clientes',
          breadcrumbs: ['Clientes'],
        },
      },
      {
        path: '/offices',
        name: 'offices',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/offices/OfficesIndex.vue'),
        meta: {
          pageTitle: 'Sucursales',
          breadcrumbs: ['Sucursales'],
        },
      },
      {
        path: '/coupons',
        name: 'coupons',
        role: ['ADMIN', 'CALL_CENTER', 'CUPONES'],
        component: () => import('@/views/clinic/cupones/Index.vue'),
        meta: {
          pageTitle: 'Cupones',
          breadcrumbs: ['Cupones'],
        },
      },
      {
        path: '/offices/create',
        name: 'offices-create',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/offices/OfficesCreate.vue'),
        meta: {
          pageTitle: 'Sucursales',
          breadcrumbs: ['Crear Sucursales'],
        },
      },
      {
        path: '/offices/edit',
        name: 'offices-edit',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/offices/OfficesEditar.vue'),
        meta: {
          pageTitle: 'Sucursales',
          breadcrumbs: ['Editar Sucursales'],
        },
      },
      {
        path: '/laboratories',
        name: 'laboratories',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () =>
          import('@/views/clinic/laboratories/LaboratoriesIndex.vue'),
        meta: {
          pageTitle: 'Laboratorios',
          breadcrumbs: ['Laboratorios'],
        },
      },
      {
        path: '/orders',
        name: 'orders',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/orders/OrdersIndex.vue'),
        meta: {
          pageTitle: 'Ordenes',
          breadcrumbs: ['Ordenes'],
        },
      },
      {
        path: '/statistics/products',
        name: 'statistics products',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () =>
          import('@/views/clinic/statistics/products/MainStatistics.vue'),
        meta: {
          pageTitle: 'Estadísticas',
          breadcrumbs: ['Pruebas de Laboratorio'],
        },
      },
      {
        path: '/statistics/ambulances',
        name: 'statistics ambulances',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () =>
          import('@/views/clinic/statistics/ambulances/MainStatistics.vue'),
        meta: {
          pageTitle: 'Estadísticas',
          breadcrumbs: ['Ambulancias'],
        },
      },
      {
        path: '/statistics/consultas-medicas',
        name: 'statistics consultas-medicas',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () =>
          import(
            '@/views/clinic/statistics/consultas-medicas/MainStatistics.vue'
          ),
        meta: {
          pageTitle: 'Estadísticas',
          breadcrumbs: ['Consultas Médicas'],
        },
      },
      {
        path: '/statistics/suscripciones',
        name: 'statistics suscripciones',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () =>
          import('@/views/clinic/statistics/paquetes/MainStatistics.vue'),
        meta: {
          pageTitle: 'Estadísticas',
          breadcrumbs: ['Planes de Salud B2C'],
        },
      },
      {
        path: '/statistics/suscripciones-b2b',
        name: 'statistics suscripciones b2b',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () =>
          import('@/views/clinic/statistics/paquetes_b2b/MainStatistics.vue'),
        meta: {
          pageTitle: 'Estadísticas',
          breadcrumbs: ['Planes de Salud B2B'],
        },
      },
      {
        path: '/statistics/farmacia',
        name: 'statistics farmacia',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () =>
          import('@/views/clinic/statistics/farmacia/MainStatistics.vue'),
        meta: {
          pageTitle: 'Estadísticas',
          breadcrumbs: ['Farmacia'],
        },
      },
      {
        path: '/statistics/global',
        name: 'statistics global',
        role: ['ADMIN', 'ESTADISTICO'],
        component: () =>
          import('@/views/clinic/statistics/global/MainStatistics.vue'),
        meta: {
          pageTitle: 'Estadísticas',
          breadcrumbs: ['Globales'],
        },
      },
      {
        path: '/orders/create',
        name: 'orders-create',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/orders/OrdersCreate.vue'),
        meta: {
          pageTitle: 'Ordenes',
          breadcrumbs: ['Crear Ordenes'],
        },
      },
      {
        path: '/orders/:id/:user',
        name: 'orders-detail',
        role: [
          'ADMIN',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'LABORATORIO',
          'CALL_CENTER',
          'ESTADISTICO',
        ],
        component: () => import('@/views/clinic/orders/OrdersDetail.vue'),
        meta: {
          pageTitle: 'Detalle de Ordenes',
          breadcrumbs: ['Detalle de Ordenes'],
        },
        props: true,
      },
      {
        path: '/products',
        name: 'products',
        role: ['ADMIN', 'CALL_CENTER'],
        component: () => import('@/views/clinic/products/ProductsIndex.vue'),
        meta: {
          pageTitle: 'Productos',
          breadcrumbs: ['Productos'],
        },
      },
      {
        path: '/emergencies',
        name: 'emergencies',
        role: ['ADMIN', 'CALL_CENTER', 'CONVENIO'],
        component: () => import('@/views/clinic/emergencies/Vertical.vue'),
        meta: {
          pageTitle: 'Emergencias',
          breadcrumbs: ['Emergencias'],
        },
      },
      {
        path: '/programados',
        name: 'programados',
        role: ['ADMIN', 'CALL_CENTER', 'CONVENIO'],
        component: () => import('@/views/clinic/programados/Vertical.vue'),
        meta: {
          pageTitle: 'Programados',
          breadcrumbs: ['Programados'],
        },
      },
      {
        path: '/suscripciones/pruebas-covid',
        name: 'suscripciones-pruebas-covid',
        role: [
          'ADMIN',
          'CALL_CENTER',
          'SELLER',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
        ],
        component: () => import('@/views/clinic/paquetes/COVID/Vertical.vue'),
        meta: {
          pageTitle: 'Prueba COVID',
          breadcrumbs: ['Prueba COVID'],
        },
      },
      {
        path: '/suscripciones',
        name: 'suscripciones',
        role: ['ADMIN', 'CALL_CENTER', 'SELLER'],
        component: () => import('@/views/clinic/paquetes/Order/Vertical.vue'),
        meta: {
          pageTitle: 'Planes de Salud',
          breadcrumbs: ['Planes de Salud'],
        },
      },
      {
        path: '/vendedores',
        name: 'vendedores',
        role: ['ADMIN', 'CALL_CENTER', 'SELLER'],
        component: () => import('@/views/clinic/seller/Seller.vue'),
        meta: {
          pageTitle: 'Planes de Salud',
          breadcrumbs: ['Planes de Salud'],
        },
      },
      {
        path: '/suscripciones-user',
        name: 'suscripciones user',
        role: ['ADMIN', 'CALL_CENTER', 'SELLER', 'SELLER_LIMITED'],
        component: () =>
          import('@/views/clinic/paquetes/OrderUser/Vertical.vue'),
        meta: {
          pageTitle: 'Planes de Salud',
          breadcrumbs: ['Planes de Salud'],
        },
      },
      {
        path: '/consultas-medicas/order-detail/:id',
        name: 'profile-consultas-medicas-detail',
        role: [
          'ADMIN',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'CALL_CENTER',
          'ESTADISTICO',
        ],
        component: () => import('@/views/consulta-medica/Index.vue'),
        meta: {
          pageTitle: 'Detalle de Consultas',
          breadcrumbs: ['Detalle de Consultas'],
        },
      },
      {
        path: '/suscripciones/order-detail/:id',
        name: 'suscripciones-detail',
        role: [
          'ADMIN',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'CALL_CENTER',
          'SELLER',
          'SELLER_LIMITED',
          'ESTADISTICO',
        ],
        component: () => import('@/views/paquete/Index.vue'),
        meta: {
          pageTitle: 'Detalle de Plan de Salud',
          breadcrumbs: ['Detalle de Plan de Salud'],
        },
      },
      {
        path: '/seguro/order-detail/:id',
        name: 'seguro-detail',
        role: [
          'ADMIN',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'CALL_CENTER',
          'SELLER',
          'SELLER_LIMITED',
          'ESTADISTICO',
        ],
        component: () => import('@/views/clinic/seguros/OrderDetail.vue'),
        meta: {
          pageTitle: 'Detalle de Órden de Seguro',
          breadcrumbs: ['Detalle de Órden de Seguro'],
        },
      },
      {
        path: '/suscripciones-b2b-usuario/order-detail/:id',
        name: 'suscripciones-detail-user',
        role: [
          'ADMIN',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'CALL_CENTER',
          'SELLER',
          'ESTADISTICO',
        ],
        component: () => import('@/views/paquete/IndexUsers.vue'),
        meta: {
          pageTitle: 'Detalle de Plan de Salud',
          breadcrumbs: ['Detalle de Plan de Salud'],
        },
      },
      {
        path: '/suscripciones-b2b-empresas/order-detail/:id',
        name: 'profile-suscripciones-detail',
        role: [
          'ADMIN',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'CALL_CENTER',
          'SELLER',
          'ESTADISTICO',
        ],
        component: () => import('@/views/paquete/IndexEmpresas.vue'),
        meta: {
          pageTitle: 'Detalle de Plan de Salud',
          breadcrumbs: ['Detalle de Plan de Salud'],
        },
      },
      {
        path: '/farmacia/orders/:id',
        name: 'farmcacia-orders-detail',
        role: [
          'ADMIN',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'CALL_CENTER',
          'ESTADISTICO',
        ],
        component: () => import('@/views/pharmacy/Index.vue'),
        meta: {
          pageTitle: 'Detalle de Pedido',
          breadcrumbs: ['Detalle de Pedido'],
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/clinic/profile/Profile.vue'),
        meta: {
          breadcrumbs: ['Perfil'],
        },
        children: [
          {
            path: 'orders',
            name: 'profile-orders',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
              'EMPRESARIAL',
            ],
            component: () => import('@/views/clinic/profile/Orders.vue'),
            meta: {
              pageTitle: 'Ordenes',
            },
          },
          {
            path: 'mis-citas',
            name: 'profile-mis-citas',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () => import('@/views/clinic/profile/MisCitas.vue'),
            meta: {
              pageTitle: 'Citas',
            },
          },
          {
            path: 'farmacia',
            name: 'profile-farmacia',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () => import('@/views/clinic/profile/Pharmacy.vue'),
            meta: {
              pageTitle: 'Farmacia',
            },
          },
          {
            path: 'consultas-medicas',
            name: 'profile-consultas-medicas',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () => import('@/views/clinic/profile/Consultas.vue'),
            meta: {
              pageTitle: 'Consultas Medicas',
            },
          },
          {
            path: 'suscripciones',
            name: 'profile-suscripciones',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () => import('@/views/clinic/profile/Paquetes.vue'),
            meta: {
              pageTitle: 'Planes de Salud',
            },
          },
          {
            path: 'suscripciones-comerciales',
            name: 'profile-suscripciones-comerciales',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () =>
              import('@/views/clinic/profile/PaquetesPolicia.vue'),
            meta: {
              pageTitle: 'Planes de Salud Comerciales',
            },
          },
          {
            path: 'suscripciones-b2b',
            name: 'profile-suscripciones-b2b',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () => import('@/views/clinic/profile/PaquetesB2B.vue'),
            meta: {
              pageTitle: 'Planes de Salud',
            },
          },
          {
            path: 'projects',
            name: 'profile-projects',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () =>
              import('@/views/crafted/pages/profile/Projects.vue'),
            meta: {
              pageTitle: 'Proyectos',
            },
          },
          {
            path: 'projects',
            name: 'profile-projects',
            role: [
              'ADMIN',
              'CLIENTE',
              'COMERCIAL',
              'EMPRESARIAL',
              'CALL_CENTER',
            ],
            component: () =>
              import('@/views/crafted/pages/profile/Projects.vue'),
            meta: {
              pageTitle: 'Proyectos',
            },
          },
        ],
      },
      {
        path: '/laboratory',
        name: 'laboratory',
        component: () => import('@/views/clinic/laboratory/Profile.vue'),
        meta: {
          breadcrumbs: ['Perfil'],
        },
        children: [
          {
            path: 'orders',
            name: 'laboratory-orders',
            role: ['ADMIN', 'LABORATORIO', 'CALL_CENTER'],
            component: () => import('@/views/clinic/laboratory/Orders.vue'),
            meta: {
              pageTitle: 'Ordenes',
            },
          },
          {
            path: 'projects',
            name: 'laboratory-projects',
            role: ['ADMIN', 'LABORATORIO', 'CALL_CENTER'],
            component: () =>
              import('@/views/crafted/pages/profile/Projects.vue'),
            meta: {
              pageTitle: 'Proyectos',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/sign-in/:code1/:code2',
        // path: '/sign-in',
        name: 'sign-in-2',
        role: [
          'USER_ROLE',
          'ADMIN',
          'CALL_CENTER',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'LABORATORIO',
          '',
          null,
          undefined,
        ],
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
        meta: {
          pageTitle: 'Iniciar Sesión',
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        // path: '/sign-in/:code1/:code2',
        path: '/sign-in',
        name: 'sign-in',
        role: [
          'USER_ROLE',
          'ADMIN',
          'CALL_CENTER',
          'CLIENTE',
          'COMERCIAL',
          'EMPRESARIAL',
          'LABORATORIO',
          '',
          null,
          undefined,
        ],
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
        meta: {
          pageTitle: 'Iniciar Sesión',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  let res = to.matched[0].children.find((elem) => {
    if (typeof elem['children'] != 'undefined') {
      return elem['children'].find(
        (item) =>
          item['name'] == to.name &&
          item['role'].includes(store.getters.currentRol)
      );
    } else if (typeof elem['name'] != 'undefined') {
      return (
        elem['name'] == to.name &&
        elem['role'].includes(store.getters.currentRol)
      );
    }
  });

  if (typeof res != 'undefined') {
    next();
  } else {
    if (store.getters.isUserAuthenticated) {
      // next();
      router.push({
        name: store.getters.currentRouter[store.getters.currentRol],
      });
    } else {
      next();
    }
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
});

export default router;
